import React, {useCallback, useEffect, useState} from 'react';
import { Filter } from '../../../utils/graphql/generatedTS/graphql';
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  Typography
} from '@mui/material';
import { Broadcaster } from '../../../utils/constants/Broadcaster';
import { DeviceTypeFilter } from '../../../utils/constants/DeviceTypeFilter';
import { ChannelData, CHANNELS_LIST } from '../../../utils/constants/Channels';
import { OnlineVideoFilter } from '../../../utils/constants/OnlineVideoFilter';

/**
 *
 * @param {React.PropsWithChildren<IFilterRow>} props
 * @return {JSX.Element}
 * @constructor
 */
const FilterRow: React.FC<IFilterRow> = (props) => {

  /**
   * Add / remove boardcaster from the filter
   */


  const [selectedChannels, setSelectedChannels] = useState<string[]>([]);


  const toggleBroadcaster = useCallback((b: Broadcaster) => {
    if (!props.filter.broadcaster) return;
    const currentBroadcaster = [...props.filter.broadcaster] as Broadcaster[];
    // if only one is already selected and we're trying to remove the last one, return the function
    if (currentBroadcaster.length === 1 && currentBroadcaster[0] === b) return;
    let newBroadcaster: Broadcaster[] = [];
    // in case all the elements were selected
    if (currentBroadcaster.length === 0) {
      newBroadcaster = Object.values(Broadcaster).filter(el => el !== b);
    } else {
      // need to understand if add or remove the Broadcaster from the list
      if (currentBroadcaster.includes(b)) newBroadcaster = currentBroadcaster.filter(el => el !== b);
      else currentBroadcaster.push(b);
    }
    props.updateFilter({
      ...props.filter,
      broadcaster: newBroadcaster
    })
  }, [props.filter, props.updateFilter]);


  /**
   * Add / remove device type from the filter
   */
  const toggleDeviceType = useCallback((d: DeviceTypeFilter) => {
    if (!props.filter.deviceType) return;
    const currentDeviceType = [...props.filter.deviceType] as DeviceTypeFilter[];
    // if only one is already selected and we're trying to remove the last one, return the function
    if (currentDeviceType.length === 1 && currentDeviceType[0] === d) return;
    let newDeviceTypeFilter: DeviceTypeFilter[] = [];
    // in case all the elements were selected
    if (currentDeviceType.length === 0) {
      newDeviceTypeFilter = Object.values(DeviceTypeFilter).filter(el => el !== d);
    } else {
      // need to understand if add or remove the DeviceType from the list
      if (currentDeviceType.includes(d)) newDeviceTypeFilter = currentDeviceType.filter(el => el !== d);
      else currentDeviceType.push(d);
    }
    props.updateFilter({
      ...props.filter,
      deviceType: newDeviceTypeFilter
    })
  }, [props.filter, props.updateFilter]);

  /**
   * Add / remove channel from the filter
   */
  const toggleChannel = useCallback((c: ChannelData | 'all') => {
    let newChannels: string[];

    if (c === 'all') {
      if (selectedChannels.length === CHANNELS_LIST.length - 1) {
        // If all channels are selected, select only the first non-"No TV channel" option
        const firstChannel = CHANNELS_LIST.find(channel => channel.id !== 0);
        newChannels = firstChannel ? [firstChannel.id.toString()] : [];
      } else {
        // Select all channels except "No TV channel"
        newChannels = CHANNELS_LIST.filter(channel => channel.id !== 0).map(channel => channel.id.toString());
      }
    } else if (c.id === 0) {
      newChannels = selectedChannels.includes("none") ? [] : ["none"];
    } else {
      newChannels = selectedChannels.filter(id => id !== "none");
      if (newChannels.includes(c.id.toString())) {
        newChannels = newChannels.filter(id => id !== c.id.toString());
      } else {
        newChannels.push(c.id.toString());
      }
      // If no channels are selected, select the first non-"No TV channel" option
      if (newChannels.length === 0) {
        const firstChannel = CHANNELS_LIST.find(channel => channel.id !== 0);
        if (firstChannel) {
          newChannels = [firstChannel.id.toString()];
        }
      }
    }

    setSelectedChannels(newChannels);
    props.updateFilter({
      ...props.filter,
      channel: newChannels
    });
  }, [selectedChannels, props.filter, props.updateFilter]);

  const allChannelsSelected = selectedChannels.length === CHANNELS_LIST.length - 1 && !selectedChannels.includes("none");

  useEffect(() => {
    if (props.filter === undefined) {
      setSelectedChannels([]);
    } else if (!props.filter.channel || props.filter.channel.length === 0) {
      const defaultChannels = CHANNELS_LIST.filter(channel => channel.id !== 0).map(channel => channel.id.toString());
      setSelectedChannels(defaultChannels);
    } else {
      setSelectedChannels(props.filter.channel.filter((id): id is string => id !== null && id !== undefined));
    }
  }, [props.filter]);

  const renderChannelSelectorLabel = () => {
    if (selectedChannels.includes("none")) return "No TV channels";
    if (allChannelsSelected) return "All channels selected";
    if (selectedChannels.length === 0) return "Select channels";
    let valueToShow = CHANNELS_LIST.find(c => c.id.toString() === selectedChannels[0])?.name as string;
    if (selectedChannels.length > 1) valueToShow += ` and ${selectedChannels.length-1} more`;
    return valueToShow;
  };
  

  /**
   * Add / remove online video from the filter
   */
  const [filter, setFilter] = useState(() => ({
    ...props.filter,
    onlineVideo: Object.values(OnlineVideoFilter)
  }));

  const toggleOnlineVideo = useCallback((value: OnlineVideoFilter) => {
    setFilter(prevFilter => {
      const currentOnlineVideo = prevFilter.onlineVideo || [];
      if (currentOnlineVideo.includes(value)) {
        return {
          ...prevFilter,
          onlineVideo: currentOnlineVideo.filter(v => v !== value)
        };
      } else {
        return {
          ...prevFilter,
          onlineVideo: [...currentOnlineVideo, value]
        };
      }
    });
  }, []);


  return (
    <Box display={"flex"}>
      {/* Boardcaster filter */}
      <Box display={"flex"}
           flexDirection={"column"}
           mt={4}
      >
        <Typography variant={"body1"}>Streaming services contacts by Broadcaster</Typography>
        <FormGroup sx={{mt: 1}}>
          {
            Object.values(Broadcaster).map((b) =>
                <FormControlLabel value={b} key={b}
                                  control={<Checkbox color={"primary"} size={"small"}
                                                     disableRipple
                                                     onClick={() => toggleBroadcaster(b)}
                                                     checked={props.filter?.broadcaster?.length === 0 || !!props.filter?.broadcaster?.includes(b)}/>}
                                  // todo: implement better solution to handle more than two broadcasters
                                  label={b === Broadcaster.SANOMA ? "Sanoma" : "MTV"}/>
            )
          }
        </FormGroup>
      </Box>

      {/* Channel list selector */}
      <Box display={"flex"} flexDirection={"column"} ml={6} mt={4}>
        <Typography variant={"body1"}>TV contacts by channel</Typography>
        <FormControl sx={{mt: 2, width: 300, '& .MuiOutlinedInput-root': {'& fieldset': {borderRadius: '10px',}}}}>
          <Select
              labelId="filter-by-channel-label"
              color={"primary"}
              multiple
              value={selectedChannels}
              size={"small"}
              input={<OutlinedInput size={"small"} color={"secondary"}/>}
              renderValue={renderChannelSelectorLabel}
              displayEmpty
          >
            <MenuItem dense onClick={() => toggleChannel('all')}>
              <Switch
                  checked={allChannelsSelected}
                  size={"small"}
                  onChange={() => toggleChannel('all')}
              />
              <ListItemText primary={"Select All"} />
            </MenuItem>
            <Divider/>
            {CHANNELS_LIST.map((c) => (
                <MenuItem key={c.id} value={c.id === 0 ? "none" : c.id.toString()} dense onClick={() => toggleChannel(c)}>
                  <Checkbox
                      checked={c.id === 0 ? selectedChannels.includes("none") : selectedChannels.includes(c.id.toString())}
                      size={"small"}
                      disabled={
                          (c.id === 0 && selectedChannels.length > 0 && !selectedChannels.includes("none")) ||
                          (c.id !== 0 && selectedChannels.includes("none"))
                      }
                  />
                  <ListItemText primary={c.name} />
                </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>


      {/* Device type filter */}
      <Box display={"flex"}
           flexDirection={"column"}
           ml={6} mt={4}
      >
        <Typography variant={"body1"}>Contacts by device type</Typography>
        <FormGroup sx={{mt: 1}}>
          {
            Object.values(DeviceTypeFilter).map((d) =>
                <FormControlLabel value={d} key={d}
                                  control={<Checkbox color={"primary"} size={"small"}
                                                     disableRipple
                                                     onClick={() => toggleDeviceType(d)}
                                                     checked={props.filter?.deviceType?.length === 0 || !!props.filter?.deviceType?.includes(d)}/>}
                                  label={d === DeviceTypeFilter.TV_SCREEN ? "TV Screen" : "Small Screen"}/>
            )
          }
        </FormGroup>
      </Box>

      {/* Online Video filter */}
      <Box display={"flex"}
           flexDirection={"column"}
           ml={6} mt={4}
      >
        <Typography variant={"body1"}>Contacts by online video</Typography>
        <FormGroup sx={{mt: 1}}>
          {
            Object.values(OnlineVideoFilter).map((v) =>
                <FormControlLabel value={v} key={v}
                                  control={<Checkbox color={"primary"} size={"small"}
                                                     disableRipple
                                                     onChange={() => toggleOnlineVideo(v)}
                                                     checked={filter.onlineVideo.includes(v)}/>}
                                  label={v === 'is_platform' ? 'Sanoma Online Video' : v}/>
            )
          }
        </FormGroup>
      </Box>


    </Box>
  );
};

export interface IFilterRow {
  filter: Filter;
  updateFilter: (f: Filter) => void;
}

export default FilterRow;
