import React, {useCallback, useMemo} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RouteKey } from '../../../App.Routes';
import CommonPageBaseLayout from '../../organism/Common.PageBaseLayout/Common.PageBaseLayout';
import {Alert, Box, CircularProgress, Typography} from '@mui/material';
import { ArrowBackRounded, EventAvailableRounded } from '@mui/icons-material';
import { useQuery } from '@apollo/client';
import { ReportQueries } from '../../../utils/graphql/queries/report';
import { generateClientContextWithHeaders } from '../../../utils/graphql/graphQLsupportFunction';
import { useAuthContext } from '../../../context/AuthContext';
import { format } from 'date-fns';
import ReportTabSelector from '../../organism/Report.TabSelector/Report.TabSelector';
import { ReportFileCacheProvider } from '../../../context/ReportFileCacheContext';
import ReportDownloadExcelButton from '../../organism/Report.DownloadExcelButton/Report.DownloadExcelButton'
import GradientCircularProgress from "../../organism/Dashboard.ReportTable/GradientCircularProgress";


/**
 *
 * @param {React.PropsWithChildren<IReport>} props
 * @return {JSX.Element}
 * @constructor
 */



const Report: React.FC<IReport> = (props) => {
  const navigate = useNavigate();
  const {idTeam, idReport} = useParams();
  const {authToken} = useAuthContext();

  const { data: report, loading } = useQuery(ReportQueries.GET_REPORT_BY_ID, {
    variables: {
      idReport: idReport as string
    },
    context: generateClientContextWithHeaders(authToken),
    skip: !authToken || !idReport
  });


  const goBack = useCallback(() => {
    navigate(RouteKey.Dashboard.replace(":idTeam", idTeam as string));
  }, [idTeam]);


  const isBroadcasterInFilter = useMemo(() => {
    const broadcaster = report?.getReportById?.filter?.broadcaster;
    return Array.isArray(broadcaster) && broadcaster.length > 0;
  }, [report?.getReportById?.filter?.broadcaster]);

  const isChannelInFilter = useMemo(() => {
    const channel = report?.getReportById?.filter?.channel;
    return Array.isArray(channel) && channel.length > 0;
  }, [report?.getReportById?.filter?.channel]);

  const isDeviceInFilter = useMemo(() => {
    const device = report?.getReportById?.filter?.deviceType;
    return Array.isArray(device) && device.length > 0;
  }, [report?.getReportById.filter.deviceType]);

  const isOnlineVideoFilter = useMemo(() => {
    const onlineVideo = report?.getReportById?.filter?.onlineVideo;
    return Array.isArray(onlineVideo) && onlineVideo.length > 0;
  }, [report?.getReportById.filter.onlineVideo]);



  return (
    <CommonPageBaseLayout>
      <ReportFileCacheProvider>
        {/*Back arrow*/}
        <Box display={"flex"} width={"100%"} justifyContent={"space-between"}>
          <Box display={"flex"} alignItems={"center"} sx={{ cursor: "pointer" }} onClick={goBack}>
            <ArrowBackRounded fontSize={'small'} />
            <Typography variant={"body2"} ml={1}>BACK</Typography>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="flex-end" mt={2}>
            {isBroadcasterInFilter || isChannelInFilter || isDeviceInFilter || isOnlineVideoFilter ? (
                <Alert severity={"info"} sx={{ width: '450px', textAlign: 'right', borderRadius: '10px' }}>
                  This report has been filtered — Check info tab for more details
                </Alert>
            ) : null}
            {report && !loading && report.getReportById.hasWarnings ? (
                <Alert severity={"warning"} sx={{ width: '450px', textAlign: 'right', mt: 1, borderRadius: '10px' }}>
                  This report contains warnings — check Info tab for more details
                </Alert>
            ) : null}
          </Box>
        </Box>

        {
          loading || !report?.getReportById ?
            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} mt={4}>
              <GradientCircularProgress/>
            </Box>
            :
            <>
              {/* Title and subtitle */}
              <Typography variant={"h1"} fontWeight={600} mt={4}>
                {report?.getReportById.campaignName}
              </Typography>

              <Box mt={2} display={"flex"} justifyContent={"space-between"}>

                <Box display={"flex"}>
                  {/* Created Date*/}
                  <Box display={"flex"} flexDirection={"column"}>
                    <Typography variant={"body2"}>CREATED DATE</Typography>
                    <Box mt={0.5} display={"flex"} alignItems={"center"}>
                      <EventAvailableRounded sx={{fontSize: 20}}/>
                      <Typography variant={"body1"} sx={{ml: 0.5, fontWeight: "bold"}}>
                        {format(report?.getReportById.createdAt as number * 1000, "dd/MM/yyyy")}
                      </Typography>
                    </Box>
                  </Box>

                  {/*Created By*/}
                  <Box display={"flex"} flexDirection={"column"} ml={5}>
                    <Typography variant={"body2"}>CREATED BY</Typography>
                    <Box mt={0.5} display={"flex"} alignItems={"center"}>
                      {/*<PersonOutlineRounded sx={{fontSize: 20}}/>*/}
                      <img src={"/imgs/user-icon.svg"} height={20} alt={"User logo"}/>
                      <Typography variant={"body1"} sx={{ml: 0.5, fontWeight: "bold"}}>
                        {report?.getReportById.userCreator.firstName} {report?.getReportById.userCreator.lastName}
                      </Typography>
                    </Box>
                  </Box>
                </Box>


                {/* Download Excel report */}
                {
                  idReport ?
                    <Box>
                      <ReportDownloadExcelButton idReport={idReport}/>
                    </Box> : ""
                }
              </Box>

              <Box sx={{backgroundColor: "white"}} borderRadius={2} mt={2} paddingX={2} paddingY={4}>
                <ReportTabSelector report={report.getReportById}/>
              </Box>
            </>
        }
      </ReportFileCacheProvider>
    </CommonPageBaseLayout>
  );
};

export interface IReport {

}

export default Report;
